<template>
  <div class="jump">
    <!-- <p style="24px">
      正在跳转到支付页面，请稍候~
    </p> -->
    <img src="@/assets/images/loading.gif" alt="" />
  </div>
</template>

<script>
import {getRedirectUrl} from '@/api/api';
import config from "@/config";
// import wayCodeUtils from "@/utils/wayCode";

// 分发器页面，  用户扫码后进入的第一个页面：
// 判断是否存在channelUserId, 如果存在则直接跳到 对应的支付页面，如果不存在则重定向到对应的页面。
export default {
  components: {
  },

  mounted() {

    console.log("正在跳转", this.$router.params)
    const that = this;
    //以下为不存在
    getRedirectUrl().then(res => {
      console.log("请求返回参数", res)
      if(!res.redirectType) {
        return alert("Redirect Params Error");
      }
      if(res.redirectType === 0 ) { //直接跳转订单
        location.href = res.redirectParam;
      }else{ //跳转对应支付方式的页面
        this.$router.push({name: res.redirectParam})
      }
    }).catch(res => {
      that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
    });
  }
}
</script>
<style lang="css" scoped>
  .jump {
    height:100vh;
    width: 100vw;
    display:flex;
    justify-content: center;
    align-items: center;
  }
</style>
